.headway-badge {
	color: $primary;
	height: 64px;
	padding: 0px;

	svg {
		vertical-align: middle;
	}
}

.HW {
	&_badge {
		left: -6px !important;
	}
	&_badge_cont {
		width: 100% !important;
		height: 64px;
		position: absolute !important;
		top: 8px;
		left: 0px;
	}
}

#HW_badge {
	background-color: $success;
}

.HW_frame_cont {
	margin-top: 10px !important;
}
