.kiosk {
	&__modal {
		min-width: 70%;
		&__title {
			margin-top: 15px !important;
		}
		&__col {
			text-align: center;
		}
		&__button {
			min-width: 100%;
			height: 45px;
			&__half {
				min-width: 50%;
			}
		}
		&__select {
			min-width: 250px;
		}
	}
	&__admin-modal {
		&__steps {
			.cuboh-steps-item
				> .cuboh-steps-item-container
				> .cuboh-steps-item-tail::after {
				background: transparent !important;
			}
			.cuboh-steps-item > .cuboh-steps-item-container > .cuboh-steps-item-icon {
				background: transparent !important;
			}
		}
	}
}
