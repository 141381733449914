.cuboh-layout-sider {
	background-color: $primary !important;
}

.cuboh-layout-sider-collapsed {
	.nav-text {
		display: none;
	}

	.cuboh-menu-submenu {
		svg + span {
			display: none;
		}
	}

	.sider-menu-logo {
		img {
			height: 64px;
			padding: 18px 4px;
		}
	}
}

.sider-menu-logo.false {
	img {
		height: 32px;
	}
}

.cuboh-menu-inline-collapsed {
	.cuboh-menu-submenu-active {
		svg {
			stroke: #fff !important;
		}
	}

	.submenu-icon {
		margin-left: -10px;
		max-width: 22px;
	}
}

.sider-menu-logo {
	position: relative;
	padding: 0 24px;
	overflow: hidden;
	cursor: pointer;
	transition: all 0.2s;
	margin-bottom: 10px;

	img {
		display: inline-block;
		height: 32px;
		vertical-align: middle;
	}

	h1 {
		display: inline-block;
		margin: 0 0 0 12px;
		color: #fff;
		font-size: 20px;
		vertical-align: middle;
		animation: fade-in;
		animation-duration: 0.3s;
	}

	a {
		display: flex;
		align-items: center;
		height: 64px;
	}
}

.trigger {
	font-size: 8px;
	padding: 22px 0px 22px 0;
	width: 50px;
	cursor: pointer;
	height: 64px;
	&:hover {
		stroke: $secondary-5;
	}
}

.trigger:hover {
	color: $microdose;
}

.new-tag {
	margin-left: 10px;
	color: $primary-8;
}

.feedback {
	height: 420px;
}

.feature-requests {
	height: 800px;
}

.cuboh-menu-title-content {
	a {
		color: #a5a5a5;
	}
}

.bottom-menu {
	li:nth-child(2) {
		background-color: $info-6;
		color: white;

		&:hover {
			background-color: $info-7 !important;
		}
	}
}
