// Branding

// State colors
$success-1: #f0fff3;
$success-2: #f0fff4;
$success-3: #d4ffe0;
$success-4: #a7fac2;
$success: rgba(121, 238, 161, 1);
$success-6: #5bc785;
$success-7: #42a169;
$success-8: #2c7a4f;
$success-9: #1d5438;

$info-1: #f0fbff;
$info-2: #f0faff;
$info-3: #c9e4f2;
$info-4: #9ac8e6;
$info: rgba(110, 172, 217, 1);
$info-6: #5287b3;
$info-7: #3a648c;
$info-8: #254466;
$info-9: #162940;

$warning-1: #fffbe6;
$warning-2: #fff1b8;
$warning-3: #ffe58f;
$warning-4: #ffd666;
$warning: rgba(250, 173, 20, 1);
$warning-6: #faad14;
$warning-7: #d48806;
$warning-8: #ad6800;
$warning-9: #874d00;
$warning-10: #613400;

$danger-1: #fff4f0;
$danger-2: #ffebe6;
$danger-3: #ffc9bd;
$danger-4: #ffa494;
$danger: rgba(250, 122, 104, 1);
$danger-6: #d4594e;
$danger-7: #ad3d37;
$danger-8: #872624;
$danger-9: #61191a;

$white: #fff;
$gray: #a5a5a5;
// Primary Colors
$primary: #16173e;
$primary-1: #75777d;
$primary-2: #696a70;
$primary-3: #535563;
$primary-4: #3a3c57;
$primary-5: #26284a;
$primary-6: #16173e;
$primary-7: #070717;
$primary-8: #000000;
// Secondary Colors
$secondary: #4845df;
$secondary-1: #f3f0ff;
$secondary-2: #f2f0ff;
$secondary-3: #d0c9ff;
$secondary-4: #a39cf7;
$secondary-5: #746eeb;
$secondary-6: #4845df;
$secondary-7: #3032b8;
$secondary-8: #1f2491;
$secondary-9: #11196b;
$secondary-10: #0a1145;

$highlight: #faff00;
$tertiary: #f0eeed;
$tertiary-light: #f8f7f6;
$tertiary-1: #ddd9d7;
$tertiary-2: #c6c3c1;
$tertiary-3: #9d9a98;

$bayarea: #a4c2d8;
$bayarea-1: #cbd5dc;
$venmo: rgba(161, 236, 187, 1);
$venmo-1: rgba(161, 236, 187, 0.4);

$microdose: rgba(209, 173, 255, 1);
$microdose-1: rgba(209, 173, 255, 0.4);
$microdose-2: rgba(209, 173, 255, 0.1);

$merchant-1: #b58265;
$merchant-2: #c697c7;
$merchant-3: #9e8ab8;
$merchant-4: #8699a8;
$merchant-5: #89ad95;
$merchant-6: #c2d689;

$tag-text-paused: #5c0011;
$tag-text-accepting: #135200;
$tag-outline-paused: #ffa39e;
$tag-text-master: #eb2f96;
$tag-outline-master: #ffadd2;

$layout-header-height: 64px;
$placeholder: #bfbfbf;
$disabled: #f5f5f5;

$colors: (
	// State colors
	success: $success,
	info: $info,
	warning: $warning,
	danger: $danger,
	white: $white,
	tertiary: $tertiary,
	// Primary Colors
	primary: $primary,
	primary-1: $primary-1,
	primary-2: $primary-2,
	primary-3: $primary-3,
	primary-4: $primary-4,
	primary-5: $primary-5,
	primary-6: $primary-6,
	primary-7: $primary-7,
	primary-8: $primary-8,
	// Secondary Colors
	secondary: $secondary,
	secondary-1: $secondary-1,
	secondary-2: $secondary-2,
	secondary-3: $secondary-3,
	secondary-4: $secondary-4,
	secondary-5: $secondary-5,
	secondary-6: $secondary-6,
	secondary-7: $secondary-7,
	secondary-8: $secondary-8
);

// https://ant.design/components/layout/
// note: added xxs and changed xs to support galaxy fold
$breakpoints: (
	xxs: 0px,
	xs: 300px,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1600px,
);
