.menu-checkout {
	background: white;

	&__footer {
		a {
			-webkit-text-decoration-skip: objects;
			background-color: initial;
			color: #16173e;
			cursor: pointer;
			outline: none;
			text-decoration: none;
			transition: color 0.3s;
		}
	}

	&__menu-container {
		padding-top: 12px;
		background: white;
		min-height: 80vh;
	}

	&__hours {
		padding-top: 5px;
		padding-bottom: 5px !important;
	}

	&__sider {
		background: white !important;
		border-left: 1px solid rgba(0, 0, 0, 0.06);
		overflow: auto;
		position: fixed;
		right: 0;
		top: 20px;
		bottom: 0;
		height: 100vh;
	}

	&__header-logout-row {
		height: 100%;
	}

	&__hours {
		padding-top: 5px;
		padding-bottom: 5px !important;
	}

	&__header-icon {
		@include media($until: 'md') {
			display: none;
		}
	}

	&__header-logout-row {
		height: 100%;
	}

	&__category-tabs {
		margin-top: 16px;
	}

	&__menu-content {
		@include media($from: 'xxs') {
			margin-top: 75px;
		}

		@include media($from: 'xs') {
			margin-top: 50px;
		}

		@include media($from: 'sm') {
			margin-top: 0px;
		}
	}

	.cuboh-layout,
	&__header {
		background: white;
	}

	.cuboh-layout-header {
		background: white;
		position: sticky;
		z-index: 100;
		top: 0;
		margin-bottom: 18px;
		width: 100%;
	}

	&__select {
		width: 100%;
	}

	&-redirect {
		&__map-container {
			@include media($from: 'xxs', $until: 'md') {
				height: 400px;
			}

			@include media($from: 'md', $until: 'xxl') {
				height: 570px;
			}

			&--map {
				height: 400px;

				@include media($from: 'xxl') {
					height: 800px;
				}
			}
		}

		&__google-map {
			width: 100%;
			height: 100%;
		}

		&__tracking-map {
			height: 100%;
			overflow: scroll;
		}
	}

	&__header-nav-text,
	&__header-nav-text:hover {
		color: black !important;
	}
}
.menu-checkout-redirect {
	&__map-container {
		margin-top: 16px;
	}
}

.map-wrap {
	position: relative;
	padding-bottom: 35%;
	padding-top: 50px;
	height: 100%;
	overflow: hidden;
	justify-content: center;
}

.map-scaled-frame {
	position: absolute;
	top: 0;
	margin: auto;
	width: 125%;
	height: 120%;
	border: none;
	transform: scale(0.8);
	transform-origin: 0 0;
	justify-self: center;
}

.map-spinner {
	height: 100%;
	width: 100%;
	text-align: center;
	padding-top: 10%;
}

.checkout-main-item__modal {
	.cuboh-modal-header {
		padding: 0;
	}

	.cuboh-modal-body {
		padding-bottom: 12px !important;
		padding-top: 0 !important;
		padding-left: 0 !important;
		padding-right: 0 !important;
		.description {
			padding-left: 24px !important;
			padding-right: 24px !important;
		}
		.modal-body-form {
			padding-left: 24px !important;
			padding-right: 24px !important;
		}
		.modal-form-submit-btn {
			padding-left: 24px !important;
			padding-right: 24px !important;
			&--kiosk {
				min-height: 50px;
			}
		}
	}

	.cuboh-modal-footer {
		padding: 0;
		border: none;
		text-align: left;
	}

	.checkout-main-item__modifier-group {
		.cuboh-col.cuboh-form-item-control {
			.cuboh-form-item-explain-error {
				position: initial;
			}
		}
	}
}

.full-screen-modal {
	height: 100vh !important;
	width: 100vw !important;
	margin: 0;
	top: 0;
	max-width: 100%;
	.cuboh-modal-body {
		height: 100vh;
	}
	.modal-form-body-wrapper {
		height: 95vh;
		overflow-y: scroll;
		padding-top: 64px;
	}
}

.non-full-screen-modal {
	.cuboh-modal-body {
		max-height: 80vh;
		overflow-y: auto;
	}
}

.shopping-cart {
	&__item {
		padding: 10px;
	}

	&__quantity {
		width: 34px;
	}

	&__drawer {
		z-index: 999;
	}

	&__float {
		bottom: 8%;
	}

	&__checkout {
		&__float {
			.cuboh-float-btn-body {
				height: 100%;
			}
			max-height: 80px;
			height: 6vh;
			right: 25%;
			bottom: 8%;
		}
	}

	&__open {
		&__float {
			.cuboh-badge-count {
				height: 40%;
				width: 40%;
				border-radius: 100%;
				justify-content: center;
				align-items: center;
				display: flex;
			}

			.cuboh-float-btn-content {
				width: 60%;
				height: 60%;
				.cuboh-float-btn-icon {
					width: 100% !important;
				}
			}
			max-height: 80px;
			max-width: 80px;
			height: 6vh;
			width: 6vh;
			bottom: 8%;
		}
	}

	&__reset {
		&__float {
			max-height: 80px;
			max-width: 80px;
			height: 6vh;
			width: 6vh;
			left: 2%;
			bottom: 8%;
		}
	}
}

.merchant__navigator__image:hover
	+ .merchant__navigator__preview-mask.cuboh-image-mask,
.merchant__navigator__preview-mask.cuboh-image-mask:hover {
	opacity: 0.95;
	transition: opacity 0.3s;
}

.merchant__navigator {
	&__tab {
		padding-left: 24px;
		padding-right: 24px;
	}

	&__image {
		@include media($from: 'xxs', $until: 'md') {
			height: 80px !important;
			width: 225px !important;
		}

		border-radius: 5px;

		@include media($from: 'md') {
			height: 125px !important;
			width: 225px !important;
		}

		object-fit: cover;
	}

	&__search {
		min-width: 250px;
		margin-right: 24px;
	}

	&__preview-mask {
		background-color: $primary;
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
		height: 30%;
	}

	&__preview-mask.cuboh-image-mask {
		opacity: 0.75;
		transition: opacity 0.3s;
		top: auto;
		background: $primary;
	}
}

.modifier__price {
	font-size: 14px;
}

.storefront-icon {
	background-color: $primary;
}

.order-stepper {
	.cuboh-steps-item-process
		> .cuboh-steps-item-container
		> .cuboh-steps-item-icon {
		background: none !important;
	}

	.cuboh-steps-item-custom
		> .cuboh-steps-item-container
		> .cuboh-steps-item-icon {
		background: none !important;
	}

	.cuboh-steps-item-custom
		.cuboh-steps-item-finish
		> .cuboh-steps-item-container
		> .cuboh-steps-item-icon {
		background: none !important;
	}

	.cuboh-steps-item-finish
		> .cuboh-steps-item-container
		> .cuboh-steps-item-content
		> .cuboh-steps-item-title::after {
		background-color: $primary;
	}
}

.order {
	&__item-description {
		.cuboh-card-meta-description {
			margin-bottom: 10px !important;
		}
	}
}

.order-card {
	.cuboh-card-head-title {
		margin-bottom: 0;
		padding-bottom: 0;
		padding-top: 16px;
	}

	.cuboh-card-body {
		margin-top: 0;
		padding-top: 0;
	}

	&__pre-line {
		white-space: pre-line;
	}
}

.checkout-main-item {
	.cuboh-image > .checkout-main-item__image--avatar {
		object-fit: cover;
	}

	.cuboh-card-cover {
		.cuboh-image > .checkout-main-item__image--cover {
			object-fit: cover;
		}
	}

	&__sold-out-ribbon {
		z-index: 5;
	}
}

.checkout-banner {
	&__image {
		object-fit: cover;
		height: 200px !important;

		@include media($from: 'md') {
			border-radius: 25px;
			height: 300px !important;
		}
	}

	&__avatar {
		height: 78px;
		width: 78px;
		position: absolute;
		top: 150px;
		left: 10px;
		background-color: white;
		border: 2px solid rgb(255, 255, 255);
		box-shadow: RGB(0, 0, 0 / 20%) 0px 2px 8px;

		@include media($from: 'md') {
			left: 25px;
			top: 250px;
		}
	}
}

.classification-avatar {
	background-color: #ffffff;
	color: #000000;
}

.checkout__radio-group {
	.cuboh-radio-button-wrapper {
		width: 65px;
	}
	.cuboh-radio-button-wrapper-checked:not(
			.cuboh-radio-button-wrapper-disabled
		) {
		color: $secondary;
		border-color: $secondary !important;
	}

	.cuboh-radio-button-wrapper-checked:not(
			.cuboh-radio-button-wrapper-disabled
		)::before {
		background-color: $secondary;
	}
}

.checkout-modal {
	.cuboh-modal-body {
		padding-left: 0;
		padding-right: 0;
	}

	&__button {
		min-height: 40px;
	}

	&__button-group {
		.cuboh-space-item {
			width: 100%;
		}
	}
}

.dropoff-address {
	&__button {
		&__text {
			max-width: 100px !important;
			transition: color 0.3s;
		}
	}

	&__button:hover .dropoff-address__button__text {
		color: $secondary;
	}

	&__modal {
		&__button {
			min-height: 32px;
		}
	}
}

.fulfillment-type {
	&__delivery,
	&__pickup {
		transition: all 0.3s;
	}

	&__delivery:not([disabled]):hover &__pickup:not([disabled]):hover {
		color: $secondary;
		border-color: $secondary;
	}

	&__loading-button {
		width: 160px;
	}
}

.line-item__collapse > .cuboh-collapse-item > .cuboh-collapse-header {
	padding: 0px;

	.cuboh-collapse-arrow svg {
		margin-bottom: 3px;
	}
}

.line-item__collapse
	> .cuboh-collapse-item
	> .cuboh-collapse-content
	> .cuboh-collapse-content-box {
	padding: 5px 0px 0px 24px;
}

.modifier-group {
	&__input-number {
		.cuboh-input-number-sm input {
			width: 32px;
		}

		.cuboh-input-number-group-addon {
			border: none;
			background-color: $white;
		}
	}
}

.cuboh-menu-light {
	background-color: #fff !important;
	border: 0 !important;

	.cuboh-menu-item {
		color: $primary !important;
		background-color: #fff !important;

		&:not(.cuboh-menu-item-selected) {
			&:hover {
				background-color: #fff !important;
				color: $secondary !important;
			}

			&:not(.cuboh-menu-submenu-selected) {
				&:hover {
					color: $secondary !important;
					background-color: #fff !important;
				}
			}
		}
	}
}

.more-info {
	&__collapse-hours {
		.cuboh-collapse-expand-icon {
			margin-top: 8px;
			padding-right: 8px;
		}
	}
}

.shopping-cart-button {
	margin-right: 12px;
}

.full-screen-modal {
	height: 100vh !important;
	width: 100vw !important;
	margin: 0;
	top: 0;
	max-width: 100%;
	.cuboh-modal-body {
		height: 100vh;
	}

	.modal-form-body-wrapper {
		height: 95vh;
		overflow-y: scroll;
		padding-top: 64px;
	}
}

.secondary-float-container {
	.cuboh-float-btn-primary {
		.cuboh-float-btn-body {
			background-color: $secondary;
		}
	}
	.cuboh-float-btn-primary:not(:disabled) {
		background-color: $secondary;
	}
	.cuboh-float-btn-primary:is(:disabled) {
		.cuboh-float-btn-body {
			.cuboh-float-btn-description {
				color: rgba(79, 79, 79, 0.25);
			}
			background: #f5f5f5;
			border-color: #d9d9d9;
			box-shadow: none;
			text-shadow: none;
		}
	}
	.cuboh-float-btn-primary:not(:disabled):hover {
		background-color: $secondary-3;
	}
	.cuboh-float-btn-primary:active {
		background-color: $secondary-4;
	}
}

.modifier-group-badge {
	margin-top: 12px;
	.cuboh-badge-status-processing {
		background-color: $danger;
		overflow: visible;
		position: absolute !important;
		color: $danger;
	}
	.cuboh-badge-status-processing::after {
		border-color: $danger !important;
	}
}

.merchant-line-items {
	margin-top: 15px; // does not work with the mixins
}
