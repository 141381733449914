// CSS for the Home page

.site-card-border-less-wrapper {
	height: 100%;
}

.welcome-container {
	display: flex;
	justify-content: space-around;
	align-items: center;
}

.welcome-row {
	margin-top: -120px;
}

/***** Trends ******/

.trend-wrapper .g2-tooltip {
	z-index: 8;
	transition: left 0.4s cubic-bezier(0.23, 1, 0.32, 1) 0s,
		top 0.4s cubic-bezier(0.23, 1, 0.32, 1) 0s;
	background-color: transparent;
	color: rgb(89, 89, 89);
	padding: 0px 12px;
	margin: 0px;
	overflow-x: auto;
	width: 100%;
	left: 0px;
	top: 0px;
	pointer-events: auto;
}

.trend-wrapper .g2-tooltip-title {
	margin: 10px 0;
	font-weight: 700;
	height: 12px;
	line-height: 12px;
}

.trend-wrapper .g2-tooltip-items {
	display: flex;
	flex-direction: row;
	align-items: center;
	overflow: auto;
	width: 100%;
}

.trend-wrapper .g2-tooltip-item {
	opacity: 1;
	cursor: pointer;
	position: relative;
	display: flex;
	flex-direction: column;
	width: 92px;
	min-width: 92px;
	padding-left: 12px;
	justify-content: space-between;
}

.trend-wrapper .g2-tooltip-item.inactive {
	opacity: 0.25;
}

.trend-wrapper .g2-tooltip-item-marker {
	width: 5px;
	position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0px;
	height: 48px;
	left: 0px;
}

.trend-wrapper .g2-tooltip-item-label {
	font-size: 14px;
	line-height: 14px;
	margin: 2px 0px 12px;
	min-height: 40px;
}

.trend-wrapper .g2-tooltip-item-value {
	font-weight: 700;
	font-size: 10px;
	line-height: 18px;
	color: rgba(0, 0, 0, 0.65);
	margin: 0px 0px 4px;
}

.wrapper {
	position: relative;
	width: 100%;
	height: 100%;
}

.wrapper .chart-wrapper {
	height: calc(100% - 88px);
}

.cuboh-card {
	.widget-borderless-grid-card {
		width: 100%;
		padding: 4px 0px 4px 0px;
		box-shadow: 0px 0 0 0 #f0f0f0, 0 0px 0 0 #f0f0f0, 0px 0px 0 0 #f0f0f0,
			0px 0 0 0 #f0f0f0 inset, 0 0px 0 0 #f0f0f0 inset;
	}
	&:hover {
		z-index: 1;
	}
}

.sales-volume-year-card {
	min-height: 260px;
}

.pos-widget__card-grid-width {
	width: '33.333333%';
}

.sales-volume-row-text-parent-col {
	min-width: 70%;
}

.sales-volume-row-text {
	min-width: 50%;
	justify-content: space-around;
}

.widget-title{
	margin-top: 0.5em !important;
}

.widget-subtitle{
	margin-top: -0.5em;
	margin-bottom: 0.5em;
}