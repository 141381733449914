#reset-password {
	.cuboh-form-item,
	.cuboh-form-item-with-help {
		margin-bottom: 32px;
	}
}

.cuboh-settings-layout-sider {
	background-color: #ffff !important;
}

.settings {
	&__tabs {
		padding: 20px 0px;
		background: white;
		box-shadow: 3px 3px 3px -3px rgba(0, 0, 0, 0.4);

		.cuboh-tabs-tab-active {
			background: $microdose-1;
		}

		label,
		.cuboh-card-head-title,
		.cuboh-tabs-tab-btn {
			color: $primary !important;
		}
	}
	.cuboh-card:not(.cuboh-card-bordered) {
		box-shadow: none;
	}
}

.storefront-upsell-card {
	background-color: $venmo;
	box-shadow: 3px 3px 3px -3px rgba(0, 0, 0, 0.4) !important;
}

.storefront-tag-color {
	background-color: $microdose;
}

.kiosk-upsell-card {
	background-color: $microdose;
	box-shadow: 3px 3px 3px -3px rgba(0, 0, 0, 0.4) !important;
}

.kiosk-store-code-tag {
	font-size: 20px !important;
	line-height: 1.4 !important;
}
