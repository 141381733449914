.table-filter {
	&__search.cuboh-input-affix-wrapper {
		width: 255px;
		border: 1px solid white;
		svg {
			color: $primary;
		}
		&:hover {
			background-color: $bayarea-1;
			border-color: transparent !important;
			input {
				background-color: $bayarea-1;
			}
		}
	}
	&__activity {
		img {
			position: relative;
			top: -2px;
		}
	}
	&__platform-dropdown {
		.cuboh-checkbox {
			position: relative;
			top: 4px;
		}
	}
}
