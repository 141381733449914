@import 'variables';
@import 'mixins';
@import 'reactFeather';
@import 'table';
@import 'authForm';
@import 'register';
@import 'header';
@import 'sider';
@import 'analytics';
@import 'integrations';
@import 'mapping';
@import 'notify';
@import 'headway';
@import 'setToken';
@import 'settings';
@import 'invoices';
@import 'menuManagement';
@import 'menuCheckout';
@import 'ecom';
@import 'storefront';
@import 'ordersControl';
@import 'ordersDisplay';
@import 'cubohTags';
@import 'multiSelect';
@import 'tableFilters';
@import 'dashboard';
@import 'widgets';
@import 'draggable';
@import 'responsive';
@import 'kiosk';
@import 'chownow';

// fonts
@font-face {
	font-family: 'Matter-ChowNow';
	src: url('../fonts/Matter-ChowNow-Regular.woff2') format('woff2');
	font-weight: normal;
}
@font-face {
	font-family: 'Matter-ChowNow';
	src: url('../fonts/Matter-ChowNow-Medium.woff2') format('woff2');
	font-weight: 500;
}
@font-face {
	font-family: 'Matter-ChowNow';
	src: url('../fonts/Matter-ChowNow-SemiBold.woff2') format('woff2');
	font-weight: 600;
}
@font-face {
	font-family: 'Matter-ChowNow';
	src: url('../fonts/Matter-ChowNow-Bold.woff2') format('woff2');
	font-weight: 700;
}

body {
	-webkit-font-smoothing: antialiased;
	-webkit-font-feature-settings: 'tnum', 'tnum';
	-webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
	font-feature-settings: 'tnum', 'tnum';
	margin: 0 !important;
}

#root,
body,
html,
.cuboh-layout {
	height: 100%;
}

.main-content {
	background-image: url(../images/food.svg);
	background-repeat: repeat;
	min-height: 100%;
	padding: 24px 24px;
	&__table {
		padding: 24px 24px 0;
	}
}

.cuboh-layout {
	overflow-x: hidden;
	overflow-y: scroll;
}

.content-wrapper {
	margin: 24px;
	padding-top: 0;

	.child-content-wrapper {
		margin: -24px -24px 0;
		height: 100%;
	}
}

.cuboh-tabs-tab-active {
	background-color: #e6f7ff;
}

.cuboh-menu-item-selected {
	a {
		color: white !important;
	}
}

.cuboh-menu-dark {
	background: $primary !important;
	.cuboh-menu {
		background: $primary !important;
	}
	.cuboh-menu-item {
		border-radius: 0px !important;

		&:hover,
		&:focus {
			background-color: $secondary !important;
		}

		a {
			color: rgba(255, 255, 255, 0.65);

			&:hover {
				color: white !important;
			}

			&:focus {
				text-decoration: none;
				color: white !important;
			}
		}

		svg {
			vertical-align: middle;
			margin-right: 10px;
		}
	}
}

.cuboh-menu-submenu-title {
	border-radius: 0px !important;
	svg {
		vertical-align: middle;
		margin-right: 10px;
	}
}

.cuboh-menu-submenu-open {
	span {
		color: white !important;
	}
}

.detail-modal {
	.cuboh-modal-content {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}
}

// Generates .text--${color} modifiers
.text {
	@include color-modifiers();
}

// Generates .bg--${color} modifiers
.bg {
	@include color-modifiers('background-color');
}

.w-100 {
	width: 100%;
}

.hidden {
	display: None;
}

.fader-enter {
	opacity: 0;
}

.fader-enter-active {
	opacity: 1;
}

.fader-exit {
	opacity: 1;
}

.fader-exit-active {
	opacity: 0;
}

.fader-enter-active,
.fader-exit-active {
	transition: opacity 250ms;
}

.cuboh-message {
	svg {
		margin-right: 5px;
		vertical-align: middle;
	}

	span {
		vertical-align: middle;
	}

	/* Sass cant parse rgb but ignores RGB css doesn't care :')*/
	.cuboh-message-dark-theme {
		.cuboh-message-notice-content {
			box-shadow: 0 3px 6px -4px RGB(255 255 255 / 12%),
				0 6px 16px 0 RGB(255 255 255 / 8%), 0 9px 28px 8px RGB(255 255 255 / 5%);
		}
	}
}

.cuboh-input-search-icon {
	display: none;
}

.cuboh-tabs-top > .cuboh-tabs-nav::before {
	border-bottom: 0;
}

.cuboh-tabs-tab-active {
	background: none;
}

.cuboh-card-meta-description {
	color: #4f4f4f;
}

.full-width {
	width: 100% !important;
}

.full-height {
	height: 100%;
}

.hidden {
	display: none;
}

.antd-country-phone-input .cuboh-select > .cuboh-select-selector {
	border: none;
}

// This hides the dropdown and search arrow
.antd-country-phone-input .cuboh-select {
	position: initial;
}

.trigger-pointer-cursor {
	cursor: pointer;
}

.spinner {
	position: static;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.2);

	.container {
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 150px;
		width: 150px;
		background-color: rgb(255, 255, 255);

		.gif {
			height: 100px;
			width: 100px;
		}
	}
}

.flex {
	display: flex;

	.align-center {
		align-items: center;
		align-content: center;
	}

	.align-end {
		align-items: flex-end;
		align-content: flex-end;
	}

	.align-around {
		align-content: space-around;
	}

	.justify-around {
		justify-content: space-around;
	}

	.justify-center {
		justify-content: center;
	}

	.justify-end {
		justify-content: flex-end;
	}
}

.text-align-center {
	text-align: center;
}

.cuboh-tabs-tab {
	&-active {
		background: none;
	}
}

.icon-spacing {
	margin-bottom: 2px;
}

.cuboh-page-header-heading {
	h2 {
		margin-top: 0px !important;
	}
}

.typography-without-margin {
	margin: 0px !important;
}

h1,
h2,
h3,
h4,
h5 {
	margin-top: 0px !important;
}

.cuboh-drawer-title {
	h4.cuboh-typography {
		margin-bottom: 0px !important;
	}
}

.app-avatar {
	-webkit-box-shadow: 0 0 3px 30x rgba(0, 0, 0, 0.03);
	-moz-box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.03);
	box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.03);
}

.cuboh-btn {
	border-radius: 2px;

	a {
		color: #fff;
	}
}

// Bug fix for progress bar within notifications messages.

.cuboh-notification {
	.cuboh-progress-status-success {
		.cuboh-progress-bg {
			background-color: $success !important;
		}
	}

	.cuboh-progress-status-info {
		.cuboh-progress-bg {
			background-color: $info !important;
		}
	}

	.cuboh-progress-status-warning {
		.cuboh-progress-bg {
			background-color: $warning !important;
		}
	}

	.cuboh-progress-status-danger {
		.cuboh-progress-bg {
			background-color: $danger !important;
		}
	}
}

.cuboh-notification-notice-icon-info {
	color: $info !important;
}

.cuboh-notification-notice-icon-warning {
	color: $warning !important;
}

.cuboh-notification-notice-icon-success {
	color: $success !important;
}

.cuboh-notification-notice-icon-danger {
	color: $danger !important;
}

.cuboh-btn[disabled],
.cuboh-btn[disabled]:active,
.cuboh-btn[disabled]:focus,
.cuboh-btn[disabled]:hover {
	background: #f5f5f5;
	border-color: #d9d9d9;
	box-shadow: none;
	color: rgba(0, 0, 0, 0.25);
	text-shadow: none;
}

.cuboh-layout-footer {
	background: #f0f2f5;
	color: #4f4f4f;
	font-size: 14px;
	padding: 24px 50px;
}

.cuboh-modal-header {
	background: #fff;
	border-bottom: 1px solid #f0f0f0;
	border-radius: 2px 2px 0 0;
	color: #4f4f4f;
	padding: 16px 24px;
}

.cuboh-modal-footer {
	background: transparent;
	border-radius: 0 0 2px 2px;
	border-top: 1px solid #f0f0f0;
	padding: 10px 16px;
	text-align: right;
}

.cuboh-modal-body {
	padding: 24px !important;

	h4 {
		div {
			&:first-child {
				font-size: 20px;
			}
		}
	}
}

.connection {
	&__one-more {
		margin-top: 15px !important;
		margin-left: 5px !important;
	}
}

.cuboh-picker-cell-range-hover::before,
.cuboh-picker-cell-range-hover-start::before,
.cuboh-picker-cell-range-hover-end::before {
	background: rgba(209, 173, 255, 0.6) !important;
}

.cuboh-picker-cell-in-view.cuboh-picker-cell-in-range.cuboh-picker-cell-range-hover-start
	.cuboh-picker-cell-inner {
	color: #fff !important;

	&:after {
		background: $primary !important;
	}
}

.qrcode {
	&__download {
		padding-right: 10px;
	}
}

.cuboh-switch-inner-unchecked {
	margin-top: -24px !important;
}

.cuboh-menu-dark {
	.cuboh-menu-submenu-active,
	.cuboh-menu-item-active,
	.cuboh-menu-submenu-open {
		.cuboh-menu-title-content,
		.cuboh-menu-item-icon,
		.submenu-icon {
			color: #fff !important;
		}
	}
}

.cuboh-btn-icon-only {
	background: none !important;
	border: none !important;
}

.cuboh-cascader-menu {
	margin: 0 !important;
}

.cuboh-card-meta-title {
	font-weight: 400 !important;
}

.no-word-break {
	span {
		word-break: normal !important;
	}
}

.supplementary {
	&__margin.cuboh-row {
		margin-top: 50px;
	}
	&__description.cuboh-descriptions {
		margin-bottom: 50px;
		box-shadow: 3px 3px 3px -3px rgb(0 0 0 / 40%);
		.cuboh-descriptions-view {
			border-radius: 2px;
		}
		.cuboh-descriptions-item-content {
			background: white;
		}
		.cuboh-descriptions-item-label {
			background: $microdose !important;
			color: $primary;
		}
	}
}
.primary-color {
	color: $primary !important;
}

.analytics,
.order,
.storefront-order,
.modifier,
.item,
.pos-error,
.adjustment,
.control,
.orders-display,
.orders-control,
.management,
.mapping,
.edit,
.storefronts,
.widget-card {
	.cuboh-card-bordered,
	.cuboh-table-content,
	.cuboh-table {
		box-shadow: 3px 3px 3px -3px rgb(0 0 0 / 40%);
		border: 0 !important;
	}

	.cuboh-result {
		background: #fff;
		box-shadow: 3px 3px 3px -3px rgb(0 0 0 / 40%);
	}
}

.bg {
	&--white {
		background: #fff;
	}
}

.beta-tag {
	z-index: 1000;
}

.green {
	color: $success;
	background-color: $success;
}

.cuboh-ribbon-wrapper {
	height: 100%;
}

.font-size-18 {
	font-size: 18px;
	label {
		font-size: 18px;
	}

	h1 {
		font-size: 32px;
		.cuboh-typography {
			font-size: 32px;
			strong {
				font-weight: 500;
			}
		}
	}

	h2 {
		font-size: 28px;
		.cuboh-typography {
			font-size: 28px;
			strong {
				font-weight: 500;
			}
		}
	}

	h3 {
		font-size: 24px;
		.cuboh-typography {
			font-size: 24px;
			strong {
				font-weight: 500;
			}
		}
	}

	h4 {
		font-size: 22px;
		.cuboh-typography {
			font-size: 22px;
			strong {
				font-weight: 500;
			}
		}
	}

	h5 {
		font-size: 20px;
		.cuboh-typography {
			font-size: 20px;
			strong {
				font-weight: 500;
			}
		}
	}

	span,
	p,
	div {
		font-size: 18px;
		strong {
			font-weight: 500;
		}
	}

	input {
		height: 40px;
		font-size: 18px;
		strong {
			font-weight: 500;
		}
	}

	.cuboh-btn {
		font-size: 18px;
		height: 40px;
	}

	.cuboh-radio-button-wrapper {
		height: 40px;
		padding-top: 4px;
	}

	.cuboh-statistic-content-value-int {
		font-size: 24px;
	}

	.cuboh-select {
		height: 40px;
		.cuboh-select-selector {
			height: 40px;
			.cuboh-select-selection-search {
				display: flex;
				align-items: center;
			}
			.cuboh-select-selection-item {
				display: flex;
				align-items: center;
			}
		}
	}
}

// Stops flickering caused by tabs. Solution taken from https://github.com/ant-design/ant-design/issues/43541
.cuboh-tabs > .cuboh-tabs-nav .cuboh-tabs-nav-operations-hidden,
.cuboh-tabs > div > .cuboh-tabs-nav .cuboh-tabs-nav-operations-hidden {
	all: revert;
}

.layout__hide-overflow-y {
	overflow-y: hidden;
}
