svg {
	vertical-align: middle !important;
}

.header {
	svg {
		stroke-width: 1.4px;
	}
}

/* No option to override ant design feedback icons -- this is the creative solution */
.cuboh-form-item-has-success .anticon-check-circle svg {
	display: none;
}

.cuboh-form-item-has-success .anticon-check-circle::before {
	content: ' ';
	display: inline-block;
	background: $success url('../icons/check.svg') center / 12px 12px no-repeat;
	border-radius: 20px;
	width: 18px;
	height: 18px;
}

.cuboh-form-item-has-error .anticon-close-circle svg {
	display: none;
}

.cuboh-form-item-has-error .anticon-close-circle::before {
	content: ' ';
	display: inline-block;
	background: $danger url('../icons/x.svg') center / 12px 12px no-repeat;
	border-radius: 20px;
	width: 18px;
	height: 18px;
}

.cuboh-input-suffix {
	margin-right: 12px;

	svg {
		stroke-width: 1.5px;
	}
}

.cuboh-input-prefix {
	svg {
		stroke-width: 1.5px;
	}
}

.cuboh-button-icon {
	svg {
		margin-left: 8px;
	}
}

.cuboh-menu-item,
.cuboh-menu-submenu-title,
.cuboh-menu-inline-collapsed-tooltip {
	svg + span {
		margin-left: 5px;
	}

	svg {
		stroke-width: 1px;
		margin-top: -2px;
	}
}

.cuboh-tag-icon {
	svg {
		padding-right: 4px;
		margin-right: 2px;
		margin-bottom: 2px;
	}

	color: $primary;
}

.cuboh-page-header-heading-title {
	svg {
		margin-bottom: 4px;
		stroke-width: 2.5px;
	}
}

.cuboh-switch-small {
	.cuboh-switch-inner-checked {
		top: -1px;
		position: relative;
	}

	.cuboh-switch-inner-unchecked {
		top: 6px;
		position: relative;
	}
}

.cuboh-switch-inner-checked,
.cuboh-switch-inner-unchecked {
	top: -1px;
	position: relative;
}

/* Create inverted colored icons for alert messaging */
.icon-fill {
	&__danger {
		background-color: $danger;
		color: #fff;
		border-radius: 20px;
		width: 18px;
		height: 18px;
		padding: 2px;
	}

	&__warning {
		background-color: $warning;
		color: #fff;
		border-radius: 20px;
		width: 18px;
		height: 18px;
		padding: 2px;
	}

	&__success {
		background-color: $success;
		color: #fff;
		border-radius: 20px;
		width: 18px;
		height: 18px;
		padding: 2px;
	}

	&__info {
		background-color: $info;
		color: #fff;
		border-radius: 20px;
		width: 18px;
		height: 18px;
		padding: 2px;
	}
}
