.tag {
	&--accepting__accepting,
	&--completed,
	&--charge {
		background-color: $venmo-1;
		outline: 1px solid $venmo;
		border: 0 !important;
		border-radius: 2px;
	}

	&--store_open__open,
	&--success {
		background-color: $success-3 !important;
		outline: 1px solid $success;
		border: 0 !important;
		border-radius: 2px;
	}

	&--paused__paused,
	&--unavailable__unavailable,
	&--inactive,
	&--pos_missing_mapping {
		outline: 1px solid $warning;
		background-color: $warning-1 !important;
		border: 0 !important;
		border-radius: 2px;
	}

	&--unsupported__unsupported,
	&--master-item__master-item,
	&--in-progress,
	&--pos_wrong_item {
		outline: 1px solid $info;
		background-color: $info-3;
		border: 0 !important;
		border-radius: 2px;
	}

	&--new,
	&--scheduled {
		background-color: $microdose-1;
		outline: 1px solid $microdose;
		border: 0 !important;
		border-radius: 2px;
	}

	&--error__error,
	&--error,
	&--store_closed__closed,
	&--cancelled,
	&--refund,
	&--pos_offline {
		background-color: $danger-3 !important;
		outline: 1px solid $danger;
		border: 0 !important;
		border-radius: 2px;
	}

	&--disabled__disabled {
		background-color: $warning;
		border: 0;
	}
	&--linked_menu__cloned {
		background-color: $venmo;
		border: 0;
	}

	&--import_source {
		&__volante,
		&__doordash,
		&__uber,
		&__skip,
		&__grubhub {
			background: $info;
			border: 0;
		}
		&__cuboh {
			background-color: $microdose;
			border: 0;
		}
		&__cloned {
			background-color: $venmo;
			border: 0;
		}
	}
	&--master__master {
		background: $tag-outline-master;
		border: 0;
		margin-inline-end: -5px;
	}
}

.cuboh-tag {
	color: $primary !important;
}
