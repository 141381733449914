
.register {
	height: 100%;

	&__content {
		overflow: auto;
		background: $primary;
	}

	&__form {
		padding: 30px;
		width: 100%;
		max-width: 550px;

		button {
			width: 100%;
		}
	}

	&__form-container {
		height: 100%;
	}

	&-footer {
		&__button {
			text-align: center;
			width: 100%;
		}
	}

	&__title {
		color: white !important;
		margin: 20px 0 !important;
	}

	&__white-text {
		color: #ffffff !important;
	}

	&__input {
		margin-bottom: 32px;
	}

	&__password-rules {
		margin-bottom: 32px;
	}

	&__next,
	&__previous {
		//font-weight: $font-body-2;
		padding: 0 64px;
	}
	&__location-select.cuboh-select-disabled {
		background: #f5f5f5;
	}
	&__phone-number {
		.cuboh-input-group-addon {
			background-color: #ffffff;
			border-color: #d9d9d9 !important;
		}

		.cuboh-input-status-error {
			border-color: #d9d9d9 !important;
		}
	}
	&__light-text {
		color: #f5f5f5;
	}

	.cuboh-input-number-group-addon {
		background: #fff !important;
	}

	.cuboh-input-number-affix-wrapper {
		&:hover {
			border: 1px solid #d9d9d9 !important;
		}
	}

	.cuboh-phone-number-input {
		.cuboh-input-suffix {
			display: none;
		}
	}

	.cuboh-form-item-feedback-icon-error {
		.anticon-close-circle {
			&:before {
				display: none !important;
			}
		}
	}

	.cuboh-layout-header {
		background: $primary !important;
	}

	.cuboh-alert {
		color: white;
	}

	.cuboh-alert-message {
		color: white;
	}

	.cuboh-checkbox {
		background: white;
		border-radius: 4px;
	}

	.cuboh-checkbox-checked {
		border: 1px solid white;
	}

	.anticon.text--success,
	.anticon.text--danger {
		position: relative;
		top: -2px;

		.text--success,
		.text--danger {
			position: relative;
			top: 0px;
		}
	}

	.cuboh-form-item,
	.cuboh-form-item-with-help {
		margin-bottom: 32px;
	}
}

@media all and (min-width: 768px) {
	.register {
		&__form {
			border-radius: 15px;
		}
	}
}

.cuboh-steps-item-process
	> .cuboh-steps-item-container
	> .cuboh-steps-item-icon {
	background-color: $microdose !important;

	.cuboh-steps-icon {
		position: relative;
		color: $primary;
	}
}

.cuboh-steps-item-finish {
	> .cuboh-steps-item-container > .cuboh-steps-item-icon {
		.cuboh-step-finish-icon {
			position: relative;
			top: -2px;
		}
	}
}

.cuboh-steps-item-title::after {
	background: #f5f5f5 !important;
}

.cuboh-steps-item-finish
	> .cuboh-steps-item-container
	> .cuboh-steps-item-content
	> .cuboh-steps-item-title::after {
	background-color: $success !important;
}

.cuboh-steps-item-finish .cuboh-steps-item-icon {
	background-color: $success !important;
}

.cuboh-steps-item-wait {
	.cuboh-steps-item-icon {
		background-color: #f5f5f5 !important;
	}
}

.cuboh-cascader-input {
	height: 34px;
}

.confirm {
	.cuboh-typography {
		color: white !important;

		a {
			color: $microdose !important;
		}
	}
}

#location {
	width: 100%;
}

.registration-stepper {
	svg {
		margin-bottom: 4px;
	}
}
