.header {
	background: $tertiary !important;
	padding: 0 !important;
	height: 50px !important;
}

.intercom--navbar {
	margin-right: 20px;
}

.cuboh-page-header {
	background: $tertiary;
	border-radius: 0px !important;
	-webkit-box-shadow: 0px 3px 3px -3px rgb(0 0 0 / 40%);
	-moz-box-shadow: 0px 3px 3px -3px rgb(0 0 0 / 40%);
	box-shadow: 0px 3px 3px -3px rgb(0 0 0 / 40%);
}

.analytics,
.order,
.storefront-order,
.control,
.adjustment,
.modifier,
.item,
.mapping,
.cancellations,
.pos-error {
	.cuboh-page-header {
		box-shadow: 0px 0px 0px !important;
	}
}

.book-menu-logo {
	width: 60px;
	height: 31px;
	background: rgba(255, 255, 255, 0.2);
	margin: 16px 24px 16px 0;
	float: left;

	img {
		width: 30px;
	}
}

.menu {
	:global(.anticon) {
		margin-right: 8px;
	}

	:global(.ant-dropdown-menu-item) {
		min-width: 160px;
	}
}

.right {
	display: flex;
	float: right;
	align-items: center;
	margin-left: auto;
	height: 100%;
	overflow: hidden;

	.action {
		display: flex;
		align-items: center;
		height: 100%;
		padding: 0 12px;
		cursor: pointer;
		transition: all 0.3s;

		> i {
			vertical-align: middle;
		}
	}
}

.cuboh-breadcrumb {
	text-transform: capitalize;
}

.cuboh-page-header-heading-title {
	display: inline-block;
	padding-right: 12px;
	font-weight: bold;
	font-size: 16px;
	line-height: 1.4;
}

.cuboh-page-header-heading-title h2,
.cuboh-page-header-heading-title h3 {
	color: $primary !important;
}

.user-profile:hover {
	cursor: pointer;

	.user-avatar {
		background-color: $primary-5 !important;
	}
}

.cuboh-dropdown-open {
	.user-avatar-active {
		svg {
			top: -2px !important;
			position: relative !important;
		}
	}
}

.user-avatar {
	svg {
		position: relative !important;
		top: -2px !important;
	}
}

.profile-dropdown {
	margin-right: 20px;
	display: block;
	height: 50px;
}

.refer-a-friend {
	height: 800px;
}
