.main-content {
	&__display {
		margin: 3px 12px;
		padding: 6px;
		height: 100%;
		overflow: hidden;
	}
}

.orders-display {
	height: 100%;
	overflow: hidden;

	&__title {
		color: #fff !important;
		border-radius: 5px;
		padding: 0 10px;
		width: 100%;
		text-align: center;
	}

	&__fulfillmentType,
	&__app {
		width: 50px;
		height: 50px;
		line-height: 50px;
		background: #fff;
		-webkit-box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.03);
		-moz-box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.03);
		box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.03);
	}

	&__footer {
		display: block;
		position: fixed;
		width: 100%;
		bottom: 0;
	}

	&__result {
		svg {
			background-color: $success;
			color: $primary;
			border-radius: 50px;
		}
	}

	&__merchantName {
		.cuboh-typography {
			color: $primary !important;
			margin-bottom: 0;
		}

		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
		padding: 5px;
	}

	&__fulfillmentTime {
		border-radius: 5px;
		padding: 2px 5px 2px 5px;
		background-color: #fff;
		color: $primary;
	}

	&__meta {
		.cuboh-card-meta-description {
			color: $primary;
			overflow: hidden;
			white-space: nowrap;
		}

		.cuboh-typography {
			color: $primary;
			margin-bottom: 0;
		}
	}

	&__skeleton {
		span {
			width: 250px !important;
			margin-bottom: 32px;
		}
	}

	&__card {
		-webkit-box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.03);
		-moz-box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.03);
		box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.03);
		-webkit-border-radius: 10px;
		-moz-border-radius: 10px;
		border-radius: 10px;
		margin: 5px;

		.cuboh-card-body {
			padding: 18px;
			.cuboh-card-meta {
				.cuboh-card-meta-detail > div:not(:last-child) {
					margin-bottom: 0;
				}
			}
		}

		.cuboh-card-actions {
			-webkit-border-radius: 10px;
			-moz-border-radius: 10px;
			border-radius: 0 0 10px 10px;
			border-top: 0;

			li {
				margin: 0;
			}
		}
	}
}

.slick-slider {
	.slick-dots {
		position: relative !important;
		bottom: 0 !important;
		margin-top: 0.5em;

		li {
			button {
				background-color: $primary-3 !important;
				height: 10px;
			}
		}

		.slick-active {
			button {
				background-color: $primary !important;
			}
		}
	}
}

.status {
	&__ready {
		background: $success;
		color: $primary !important;
	}

	&__inProgress {
		background: $info;
		color: $primary !important;
	}

	&__new {
		background: $info;
		color: $primary !important;
	}
}
