.chownow-menu-management {
	.chownow-page-header {
		border-bottom: 1px solid #dad8d2;
		padding: 40px 56px 28px 56px;
	}
	.chownow-tabs-tab-btn {
		font-weight: 500;
		&:not(.chownow-tabs-tab-active) {
			color: #6E6E6E;
		}
	}
}

.chownow-analytics,
.chownow-report {
	.chownow-page-header {
		padding: 40px 56px;
		border-bottom: 1px solid #dad8d2;
	}
}
.table-filter {
	&__platform-dropdown,
	&__service-dropdown,
	&__column-visibility-dropdown,
	&__order-status-dropdown,
	&__pos-status-dropdown,
	&__order-control-dropdown,
	&__adjustment-dropdown,
	&__show-cancelled-dropdown {
		.chownow-dropdown-menu-title-content {
			align-content: center;
		}
	}
}
.order-code {
	&__button.chownow-btn {
		padding-left: 0px;
		font-weight: normal;
	}
}

.chownow-menu-management,
.chownow-analytics,
.chownow-report {
	min-height: 100%;

	// Layout
	.main-content {
		background-image: none;
		padding: 40px 56px;
	}

	.chownow-edit-menu__back-button {
		padding-bottom: 8px;
		padding-top: 0px;
	}
	.chownow-edit-menu__back-button:hover,
	.chownow-edit-menu__back-button:hover {
		color: #223ba8;
	}

	a {
		color: white;
		text-decoration: underline;
	}

	.chownow-layout-header {
		padding: 0 16px;
	}
	.chownow-header-logo {
		img {
			height: 32px;
			vertical-align: middle;
		}
	}
	.chownow-page-header-heading-extra .chownow-space-gap-col-small {
		column-gap: 16px;
	}

	// Typography
	h2,
	h4 {
		font-weight: 300;
	}
	.merchant-container__merchant-title {
		font-size: 24px;
		font-weight: 500;
	}

	.chownow-draggable-card {
		container-type: inline-size;
		&__title {
			font-weight: 500;
		}
	}
	@container (min-width: 0px) {
		.chownow-draggable-card__title {
			width: 24px;
		}
	}
	@container (min-width: 220px) {
		.chownow-draggable-card__title {
			width: 45px;
		}
	}
	@container (min-width: 250px) {
		.chownow-draggable-card__title {
			width: 75px;
		}
	}
	@container (min-width: 300px) {
		.chownow-draggable-card__title {
			width: 125px;
		}
	}
	@container (min-width: 350px) {
		.chownow-draggable-card__title {
			width: 200px;
		}
	}
	@container (min-width: 400px) {
		.chownow-draggable-card__title {
			width: 250px;
		}
	}
	@container (min-width: 425px) {
		.chownow-draggable-card__title {
			width: 300px;
		}
	}
	@container (min-width: 500px) {
		.chownow-draggable-card__title {
			width: 400px;
		}
	}
	@container (min-width: 650px) {
		.chownow-draggable-card__title {
			width: 500px;
		}
	}

	.draggable-card {
		.chownow-card-head {
			border-bottom: none;
		}
		.chownow-card-head-title {
			cursor: grab;
			cursor: -moz-grab;
			cursor: -webkit-grab;
			&:active {
				&:active {
					cursor: grabbing;
					cursor: -moz-grabbing;
					cursor: -webkit-grabbing;
				}
			}
		}
		&__active-drag.chownow-card {
			-webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.16) !important;
			-moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.16) !important;
			box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.16) !important;
			transition: box-shadow 0.2s linear;
			transition-timing-function: ease-in-out;
		}

		.chownow-card-body {
			padding-top: 0;
		}

		.chownow-card-actions > li {
			border-inline-end: none;
		}
		.chownow-card-actions {
			align-items: center;
			height: 56px;
			margin-left: 24px;
			margin-right: 24px;
		}
		&__menu-edit {
			.chownow-card-actions {
				li > span {
					display: grid;
					.chownow-switch {
						justify-self: start;
					}
				}
			}
		}
		&__integrations {
			padding: 12px 0 0 0;
			width: 100%;
			height: 32px;
		}
		&__integration {
			&-modified {
				border: solid 2px #dad8d2 !important;
			}
			&-published {
				border: solid 2px #21a369 !important;
			}
			&-in_progress {
				border: solid 2px #5268cc !important;
			}
			&-failed {
				border: solid 2px #f5563d !important;
			}
			&-inactive {
				border: solid 2px #142138 !important;
			}
		}
	}

	// Edit / Add Menu Details
	.menu-details__card .chownow-card-body {
		padding: 40px;
	}
	.edit-menu {
		&__section-title {
			margin-bottom: 24px;
			font-size: 20px;
			font-weight: 500;
		}
		&__menu-details .chownow-card-body,
		&__menu-hours .chownow-card-body,
		&__menu-integrations .chownow-card-body,
		&__menu-published .chownow-card-body,
		&__menu-merchants .chownow-card-body {
			padding: 0px;
		}
		&__form-item {
			margin: 24px 0;
			padding: 0px !important;
		}
		&__add-hours-button,
		&__add-day {
			span {
				text-decoration: underline;
			}
			padding-left: 0px;
		}
		&__last-published.chownow-row {
			border: 1px solid #dad8d2;
			border-radius: 8px;
			padding: 16px;
			margin-right: 0px !important;
			margin-left: 0px !important;
		}
		&__action-button {
			width: 24px;
		}
	}

	.chownow-menu-form {
		&__float-button-save,
		&__float-button-reset {
			.chownow-float-btn-description {
				font-size: 14px !important;
				font-weight: 500 !important;
			}
		}
	}
	.editable-field__input__day-selector.chownow-select,
	.edit-menu__day-label.chownow-col {
		width: 100px !important;
	}
	.editable-field__input__hours-selector.chownow-picker {
		width: 200px;
	}

	.menu-actions {
		&__grouped-button {
			border-radius: 8px !important;
		}
	}
	chownow-menu .chownow-switch-disabled {
		border: none;
		.chownow-switch-inner {
			background-color: #f5f4f1;
		}
		.chownow-switch-handle::before {
			background-color: #dad8d2;
		}
		.chownow-switch-inner-checked,
		.chownow-switch-inner-unchecked {
			color: #b2afa4;
			border-color: #b2afa4;
		}
	}

	.merchant-container {
		&__add-menu-button.chownow-btn-dashed {
			border-radius: 30px;
			background-color: #142138 !important;
		}
		&__merchant-header {
			justify-content: space-between;
			align-items: center;
			margin-bottom: 14px;
		}
		margin-bottom: 80px;
	}
	.menu-card {
		&__create.chownow-card {
			min-height: 273px;
			.chownow-card-body {
				min-height: 216px;
			}
		}
		&__publish-button {
			text-transform: uppercase;
			font-size: 10px;
			height: 28px;
			padding: 0 16px;
			border-radius: 412px;
		}
		&__publish-button:disabled {
			background-color: #f5f4f1;
			border-color: #f5f4f1;
			color: #142138;
		}
		&__publish-button:not(:disabled) {
			background-color: #223ba8;
		}
		&__publish-button:not(:disabled):hover {
			background-color: #5268cc;
		}

		&__linked-merchants {
			width: 100%;
			text-decoration: underline;
			.chownow-select-selector {
				border: 0 !important;
				padding: 0 !important;

				.chownow-select-selection-placeholder {
					color: #142138;
				}
			}

			.chownow-select-arrow {
				display: none;
			}
		}
		&__linked-merchants:hover {
			color: #223ba8;
			.chownow-select-selection-placeholder {
				color: #223ba8;
			}
		}
	}

	.chownow-btn-icon-only {
		border: none;
	}

	// menu management tables
	.menu-search {
		&__tooltip.chownow-btn {
			border-top-right-radius: 8px;
			border-bottom-right-radius: 8px;
			border-left: none;
		}
		&__tooltip.chownow-btn:disabled {
			background-color: #ffffff;
		}
		&__tooltip.chownow-btn:visited,
		&__tooltip.chownow-btn:focus,
		&__tooltip.chownow-btn:active,
		&__tooltip.chownow-btn:hover {
			border-color: #142138;
			color: #142138;
		}

		&__header-search-bar:visited + .menu-search__tooltip,
		&__header-search-bar:focus + .menu-search__tooltip,
		&__header-search-bar:hover + .menu-search__tooltip,
		&__header-search-bar:active + .menu-search__tooltip,
		&__header-search-bar:has(+ .menu-search__tooltip:visited),
		&__header-search-bar:has(+ .menu-search__tooltip:focus),
		&__header-search-bar:has(+ .menu-search__tooltip:hover),
		&__header-search-bar:has(+ .menu-search__tooltip:active) {
			border-color: #142138;
		}
		&__header-search-bar {
			border-right: none;
		}
	}
	&__table-expand-icon {
		color: #ffffff !important;
		background-color: #142138;
		border-radius: 25px;
		padding: 2px;
	}
	&__table-expand-icon:hover,
	&__table-expand-icon:active {
		background-color: #223ba8;
		cursor: pointer;
	}

	// Reporting
	.action-bar {
		&--filters.chownow-row {
			padding: 40px 56px 0px 56px;
			box-shadow: none;
		}
	}
	.main-content {
		&__table.chownow-reporting-table,
		&__analytics {
			padding: 24px 56px;
		}
	}
	.reporting-table__badge .chownow-badge-count {
		min-width: 28px;
	}
	.analytics__export-button.chownow-btn,
	.reports__export-button.chownow-btn {
		font-weight: 400;
		a {
			text-decoration: none;
		}
	}
	.responsive {
		&__segmented.chownow-btn {
			padding: 4px 7px;
			&:not(.chownow-btn-compact-last-item) {
				border-right-color: transparent;
			}
			&:hover {
				border-color: #142138 !important;
				color: $primary !important;
			}
			&.chownow-btn-compact-last-item {
				border-radius: 0px 8px 8px 0px;
			}
			&.chownow-btn-compact-first-item {
				border-radius: 8px 0px 0px 8px;
			}
		}

		&__button-active.chownow-btn {
			border-color: #223ba8 !important;
			// border-color: $venmo;
			&:hover {
				border-color: #142138 !important;
			}
		}
	}
	.chownow-btn.table-filter {
		&__column-visibility,
		&__service,
		&__status,
		&__activity,
		&__platform {
			padding: 4px 16px !important;
			margin: 0 !important;

			svg {
				margin-left: 0px;
			}
		}
	}

	.supplementary {
		&__margin.chownow-row {
			margin-top: 40px;
			h3.chownow-typography {
				margin-bottom: 24px;
			}
		}
		&__description.chownow-descriptions {
			margin-bottom: 50px;
			.chownow-tag {
				&--neutral {
					margin-inline-end: 0px;
				}
			}
			.chownow-descriptions-item-content {
				background: white;
			}

			.chownow-descriptions-row:first-child {
				.chownow-descriptions-item-label {
					border-top-left-radius: 15px;
				}
				.chownow-descriptions-item-content {
					border-top-right-radius: 16px;
				}
			}

			.chownow-descriptions-row:last-child {
				.chownow-descriptions-item-label {
					border-bottom-left-radius: 15px;
				}
				.chownow-descriptions-item-content {
					border-bottom-right-radius: 16px;
				}
			}
		}
	}
}
.chownow-table-thead > tr > th {
	font-size: 12px;
	text-transform: uppercase;
}

// General Styling Outside of the menu management page
// to Apply to all ChowNow Components

.order-exception,
.order-meta {
	&__description {
		.chownow-descriptions-row:first-child {
			.chownow-descriptions-item-label {
				border-top-left-radius: 15px;
				border-top-right-radius: 15px;
			}
		}
	}
	&__ok-button.chownow-btn {
		width: 250px;
	}
}

.chownow-btn.menu-actions {
	&__import,
	&__create,
	&__publish,
	&__pos-refresh {
		background-color: transparent !important;
		border: none;
		width: 100%;
		height: 100%;
		border-radius: 4px;

		&:disabled {
			background-color: #dad8d2 !important;
			color: #6e6e6e;
		}
	}
}
.chownow-dropdown-menu-item {
	padding: 1px 0px !important;
	height: 48px;
}

.chownow-card.draggable-card {
	&__menu-status-popover {
		&-modified,
		&-inactive,
		&-in-progress,
		&-failed,
		&-published {
			border: none;
			.chownow-card-head {
				border-radius: 4px;
				border: none;
				text-transform: uppercase;
				color: #ffffff;
				font-size: 10px;
				font-weight: 500;
			}
			.chownow-card-body .draggable-card__popover-status {
				background-color: #ffffff;
				padding-top: 4px;
				padding-left: 0px;
			}
		}

		&-modified .chownow-card-head {
			background-color: #dad8d2;
			color: #142138;
		}
		&-inactive .chownow-card-head {
			background-color: #142138;
		}
		&-in-progress .chownow-card-head {
			background-color: #5268cc;
		}
		&-failed .chownow-card-head {
			background-color: #f5563d;
		}
		&-published .chownow-card-head {
			background-color: #21a369;
		}
	}
}

// the ANTD Message and notification components get configured at the root of the app (outside of the theme config)
// so it's prefixes always begin with "cuboh" instead of "chownow"
.chownow-message {
	color: #142138;
	box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.16);

	&--success,
	&__menu-validation-success,
	&__menu-display-success,
	&-notice-success,
	&__auto-save-success,
	&__pos-refresh-success,
	&__import-success {
		.cuboh-message-notice-content {
			.cuboh-message-success > .anticon {
				color: #0e7a4a !important;
			}
		}
	}
	&--error,
	&__menu-validation-error,
	&__menu-hours-error,
	&__menu-display-error,
	&-notice-error,
	&__auto-save-error,
	&__pos-refresh-error,
	&__import-error,
	&__publish-error,
	&__import-warning {
		.cuboh-message-notice-content {
			.cuboh-message-error > .anticon {
				color: #cc1b00 !important;
			}
		}
	}
}

.chownow-notification {
	.cuboh-notification-notice-message .cuboh-typography {
		color: #142138 !important;
	}
	border-radius: 8px !important;
	box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.16) !important;
	&__warning,
	&__error {
		.cuboh-notification-notice-icon-warning {
			color: #cc1b00 !important;
		}
	}
	&__success {
		.cuboh-notification-notice-icon-success {
			color: #0e7a4a !important;
		}
	}
	&__warning {
		.cuboh-notification-notice-btn {
			width: 100% !important;
		}
	}
	&__button {
		height: 40px;
		border-radius: 20px !important;
		&--cancel:hover {
			color: #142138 !important;
			border-color: #142138 !important;
		}
		&--accept {
			background-color: #142138 !important;
			color: #ffffff !important;
			border-color: #142138 !important;
		}
		&--accept:hover {
			background-color: #223ba8 !important;
			border-color: #223ba8 !important;
		}
	}
}
// Modals
.chownow-modal-content {
	padding: 56px 40px 40px 40px !important;
}
.chownow-modal-footer {
	text-align: center !important;
	margin-top: 40px !important;
}

// Select
.chownow-select-multiple .chownow-select-selector {
	padding-inline: 12px;
}

// Tree Select
.chownow-tree-select-dropdown
	.chownow-select-tree
	.chownow-select-tree-checkbox {
	align-self: center;
}

// Typography
.chownow-title {
	&__top-margin {
		margin-top: 24px !important;
	}
	&__bottom-margin {
		margin-bottom: 24px !important;
		&--large {
			margin-bottom: 40px !important;
		}
	}
	&__no-margin {
		margin-bottom: 0 !important;
	}
	&__page-header {
		font-size: 32px !important;
		font-weight: 500 !important;
	}
}

// Floating buttons
.chownow-float-btn-group.chownow-float-btn-group-circle {
	width: 95px;
	margin-right: 24px;
}
.chownow-float-btn-primary.chownow-float-btn-circle {
	width: 110px;
	height: 48px;
}
.chownow-float-btn-primary .chownow-float-btn-body {
	border-radius: 24px;
}
.chownow-float-btn-primary .chownow-float-btn-body:hover {
	background-color: #223ba8;
	border-color: #223ba8;
	border-radius: 24px;
}

// Input Number
.chownow-input-number .chownow-input-number-handler-wrap {
	opacity: 1 !important;
}
.chownow-input-number.chownow-input-number-in-form-item {
	width: 100%;
}
.chownow-large-font {
	font-size: 16px;
}

// dropdowns are outside of the general chownow menu management tag
.edit-menu {
	&__menu-hours-delete.chownow-btn-link,
	&__menu-hours-checkbox.chownow-checkbox-wrapper {
		width: 100% !important;
		height: 100%;
		border-radius: 4px;
		align-items: center;
	}
	&__menu-hours-delete.chownow-btn-link.chownow-btn-dangerous:disabled,
	&__menu-hours-checkbox.chownow-checkbox-wrapper:disabled,
	&__menu-hours-checkbox.chownow-checkbox-wrapper-disabled {
		color: #6e6e6e;
		background-color: #dad8d2;
		width: 100%;
		height: 100%;
	}
}
.chownow-dropdown-menu-title-content {
	width: 100%;
	height: 100%;
}
.chownow-table-wrapper.reporting-table {
	.chownow-table-cell {
		padding: 8px 8px;
	}
	.chownow-table-selection-column {
		padding-left: 24px;
	}
	.chownow-table-thead > tr > th {
		padding-top: 24px;
	}

	.chownow-table-thead > tr > th:last-child {
		padding-right: 24px;
	}
	.chownow-table-tbody > tr > td:last-child {
		padding-right: 24px;
	}
	.chownow-table-filter-trigger {
		padding: 0px 30px 0px 0px;
	}
}
.chownow-dropdown-menu-title-content {
	align-content: center;
}
.chownow-table-wrapper.cuboh_category,
.chownow-table-wrapper.reporting-table:not(.order-report-table) {
	.chownow-table {
		border-radius: 16px !important;
		border-top: 1px #dad8d2 solid !important;
		border-left: 1px #dad8d2 solid !important;
		border-right: 1px #dad8d2 solid !important;
		overflow: hidden;
	}
	.chownow-table-container {
		border-radius: 16px !important;
	}
}

.chownow-table-wrapper.reporting-table.order-report-table {
	.chownow-table {
		border-radius: 16px !important;
		border-top: 1px #dad8d2 solid !important;
		border-left: 1px #dad8d2 solid !important;
		border-right: 1px #dad8d2 solid !important;
	}
	.chownow-table-container {
		border-radius: 16px !important;
	}
	tfoot.chownow-table-summary {
		padding-bottom: 24px;
		tr:last-child td:first-child {
			border-bottom-left-radius: 16px;
		}
		tr:last-child td:last-child {
			border-bottom-right-radius: 16px;
		}
		tr:last-child td {
			padding-bottom: 24px;
		}
	}
}
.show-all__button.chownow-btn {
	width: 100%;
}
.chownow-table-wrapper.cuboh_main_item,
.chownow-table-wrapper.cuboh_modifier_group,
.chownow-table-wrapper.cuboh_modifier {
	.chownow-table {
		border-radius: 0px !important;
		border-top: 0px !important;
		border-left: 1px #dad8d2 solid !important;
		border-right: 0px #dad8d2 solid !important;
		border-bottom: 0px #dad8d2 solid !important;
	}
	.chownow-table-container {
		border-radius: 0px !important;
	}
	.chownow-table-thead {
		tr:last-child td:first-child {
			border-bottom-left-radius: 0px;
		}
		tr:last-child td:last-child {
			border-bottom-right-radius: 0px;
		}
	}
	.chownow-table-tbody {
		tr:last-child td:first-child {
			border-bottom-left-radius: 0px;
		}
		tr:last-child td:last-child {
			border-bottom-right-radius: 0px;
		}
	}
}
.chownow-table-expanded-row > .chownow-table-cell {
	background-color: #dad8d2 !important;
}
.chownow-pagination-options .chownow-select {
	align-content: center;
	.chownow-select-selector {
		height: 40px !important;
		border-radius: 20px;
		background-color: #f5f4f1;
	}
}
.chownow-pagination {
	align-items: center;
	a {
		text-decoration: none;
	}
}
.chownow-table-pagination {
	padding-top: 8px;
}
.chownow-table-thead > tr {
	td,
	th {
		border-bottom: 1px solid #142138 !important;
	}
}

.chownow__table {
	&__sorter__icons {
		height: 100%;
		margin-left: 8px;
		margin-right: 8px;
		.chownow-space-item {
			height: 16px;
		}
	}
	&__sorter__icon {
		&--up {
			vertical-align: bottom !important;
		}
		&--down {
			vertical-align: top !important;
		}

		&--active {
			color: #142138;
		}
		&--inactive {
			color: #6e6e6e;
		}
	}
}

// menu management editable price fields
.classification-selector .chownow-form-item-explain-error {
	display: none;
}
.classification-selector__error.chownow-typography {
	font-size: 14px;
	color: #cc1b00;
}
.chownow__price-field--error,
.chownow__price-field--error.chownow-input-number-focused,
.chownow__price-field--error:hover,
.chownow__price-field--error:focus,
.chownow__price-field--error:active {
	border: 1px solid #cc1b00;
}

// Drawers
.chownow-drawer-close {
	position: absolute;
	top: 20px;
	right: 0;
}
.chownow-drawer-header {
	border-bottom: none !important;
}
.chownow-drawer-body {
	padding: 24px 40px 40px 40px !important;
}

.chownow-upload {
	width: 100%;
}
.chownow-upload-list-item {
	border-radius: 8px !important;
}
.chownow-image {
	.edit-item-drawer__image.chownow-image-img + .chownow-image-mask {
		border-radius: 8px;
	}
	.edit-item-drawer__image.chownow-image-img {
		border-radius: 8px;
	}
}

.chownow-row.add-item-drawer__row {
	&__price,
	&__itemPhoto {
		margin-bottom: 40px;
	}
	&__imageUrl,
	&__photo {
		margin-bottom: 16px;
	}
}
.chownow-row.edit-item-drawer__row {
	&__externalId,
	&__allowMultipleModifiers {
		margin-bottom: 0px;
	}
}

.chownow-space:has(.chownow-space-item):has(.edit-item-drawer__selector) {
	width: 100%;
}
.destination-select__modal {
	.chownow-select {
		width: 100%;
	}
}

// radio buttons
.chownow-radio-wrapper .chownow-radio-checked .chownow-radio-inner {
	background-color: #ffffff !important;
}
.chownow-radio-wrapper .chownow-radio-checked .chownow-radio-inner::after {
	background-color: #142138 !important;
}
.chownow-radio-wrapper:hover
	.chownow-radio-checked
	.chownow-radio-inner::after {
	background-color: #223ba8 !important;
}
.chownow-radio-wrapper:hover .chownow-radio-inner,
.chownow-radio-inner:hover,
.chownow-radio-wrapper:hover .chownow-radio-checked::after {
	border-color: #223ba8 !important;
}

.chownow-tag {
	&--default {
		background-color: #ffffff;
		color: #142138 !important;
		text-transform: uppercase;
		font-size: 10px;
		font-weight: 500;
		border: none;
	}
	&--neutral {
		background-color: #f5f4f1;
		color: #142138 !important;
		text-transform: uppercase;
		font-size: 10px;
		font-weight: 500;
		border: none;
	}
	&--primary {
		background-color: #142138;
		color: #ffffff !important;
		text-transform: uppercase;
		font-size: 10px;
		font-weight: 500;
		border: none;
	}
	&--secondary {
		background-color: #223ba8;
		color: #ffffff !important;
		text-transform: uppercase;
		font-size: 10px;
		font-weight: 500;
		border: none;
	}
	&--success {
		background-color: #21a369;
		color: #ffffff !important;
		text-transform: uppercase;
		font-size: 10px;
		font-weight: 500;
		border: none;
	}
	&--error {
		background-color: #f5563d;
		color: #ffffff !important;
		text-transform: uppercase;
		font-size: 10px;
		font-weight: 500;
		border: none;
	}
	&--in-progress {
		background-color: #5268cc;
		color: #ffffff !important;
		text-transform: uppercase;
		font-size: 10px;
		font-weight: 500;
		border: none;
	}
	&--new {
		background-color: #50cdf9;
		color: #142138 !important;
		text-transform: uppercase;
		font-size: 10px;
		font-weight: 500;
		border: none;
	}
	&--scheduled {
		background-color: #f6c774;
		color: #142138 !important;
		text-transform: uppercase;
		font-size: 10px;
		font-weight: 500;
		border: none;
	}
	&--inactive {
		background-color: #dad8d2;
		color: #142138 !important;
		text-transform: uppercase;
		font-size: 10px;
		font-weight: 500;
		border: none;
	}
	&--highlighted {
		background-color: #f7f8a4;
		color: #142138 !important;
		text-transform: uppercase;
		font-size: 10px;
		font-weight: 500;
		border: none;
	}
}
.chownow-steps-item-icon {
	display: none !important;
}

.chownow-space.full-width {
	.cuboh-space-item,
	.chownow-space-item {
		width: 100%;
	}
}

.chownow-icon {
	&--success {
		color: #0e7a4a;
	}
	&--error {
		color: #cc1b00;
	}
	&--warning {
		color: #cc1b00;
	}
	&--neutral {
		color: #b2afa4;
	}
}

.chownow-integration__app-avatar {
	border: solid 2px #142138 !important;
}

.chownow-integration__app-avatar.chownow-avatar-image.app-avatar {
	background: #ffffff;
	padding: 6px;
	box-shadow: none;
	img {
		border-radius: 8px;
	}
}

.chownow-publish__merchant {
	padding-top: 24px !important;
	&--0 {
		padding-top: 0px !important;
	}
}
.chownow-button {
	&__expand-link span {
		text-decoration: underline;
	}
	&__expand-link:hover {
		color: #223ba8;
	}
}

.chownow-publish-results__integration__list {
	border-radius: 8px;
	padding: 16px;
	border: 1px solid #dad8d2;
}

.chownow-tree-select-dropdown
	.chownow-select-tree
	.chownow-select-tree-indent-unit {
	width: 16px;
}

.checkout-main-item__sold-out-ribbon {
	.chownow-ribbon-text {
		text-transform: uppercase;
		font-size: 10px;
	}
}
.chownow-ribbon {
	border-radius: 4px;
}

// Analytics
.analytics {
	&__totals-card.chownow-card {
		border-radius: 8px;

		.chownow-card-body {
			padding: 16px 24px;
		}
		.chownow-statistic-content-value {
			// font-family: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji';
		}
	}

	&__graph-card.chownow-card {
		.chownow-card-head {
			padding: 24px 24px 0px 24px;
			border-bottom: none;
		}
	}
}

.prefix-icon-select-wrapper {
	.prefix-icon-wrapper {
		position: absolute;
		z-index: 1;
		width: 3rem;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.chownow-picker-range,
	.chownow-select .chownow-select-selector {
		padding-left: calc(3rem - 8px);
	}
	.chownow-select.timezone-select {
		min-width: 115px;
	}
}

// Date Picker
.chownow-picker .chownow-picker-suffix {
	color: #142138;
}

.chownow-picker-dropdown .chownow-picker-cell::before {
	height: 100%;
}
.chownow-picker-cell {
	padding: 0px !important;
	height: 36px !important;
}
.chownow-picker-dropdown
	.chownow-picker-panel-container
	.chownow-picker-presets
	ul
	li {
	min-height: 36px !important;
	align-content: center;
}
.chownow-picker-cell-inner {
	display: block !important;
	height: 100% !important;
	width: 100% !important;
	align-content: center;
}
.chownow-picker-range .chownow-picker-active-bar {
	background-color: #142138;
}
.chownow-picker-outlined:focus-within {
	border-color: #142138;
}

.chownow-navigation__tabs {
	padding: 24px 56px 8px 56px;
	&__divider {
		margin: 0px;
	}
}
