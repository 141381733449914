.menu-management {
	&__badge {
		margin-left: 6px;
		&:hover {
			color: $primary;
		}
		cursor: pointer;
	}
	&__popover {
		.cuboh-popover {
			max-width: 50%;
		}
	}
	&__popover + .cuboh-badge-count {
		margin-right: 6px;
		margin-top: 6px;
		background-color: $primary;
	}
}
.menu-management-badge {
	width: 100%;

	.cuboh-badge-multiple-words {
		background: $microdose-1 !important;
		height: 32px;
		padding: 6px 15px 6px 15px;
		color: $primary-6;
		box-shadow: none;
		border-radius: 30px;
	}
}

.menu-management-price-field.cuboh-input-number-focused {
	border: 1px;
}

.menu-management-price-field {
	min-width: 75px;
	min-height: 32px;
}

.cuboh-table-row-level-0:hover
	.menu-management-price-field:not(.cuboh-input-affix-wrapper-focused).valid {
	background-color: $venmo-1;
}

.cuboh-table-row-level-0:hover
	.menu-management-price-field:not(.cuboh-input-affix-wrapper-focused).valid
	.cuboh-input {
	background-color: $venmo-1;
}

.menu-management-price-field.valid {
	border: 0px;
}

.menu-management-price-field.error {
	border-color: $danger;
	min-width: 75px;
	margin-bottom: 24;
}

.menu-management-price-form {
	margin-bottom: 0px;
}

.cuboh-table-wrapper.categories .cuboh-table-row,
.cuboh-table-wrapper.modifierGroups .cuboh-table-row {
	height: 125px !important;
}

.schedule-list__row--valid {
	max-height: 55px;
}

.schedule-list__item--error
	.cuboh-form-item-control
	.cuboh-form-item-explain-error {
	margin-top: 22px;
	width: 100px;
}

.schedule-list__input {
	width: 55px;
	min-height: 32px;
	margin-top: 22px;
}

.cuboh-table-row-level-0:hover .schedule-list__input--valid:not(:focus) {
	background-color: $venmo-1;
}

.schedule-list__input--valid {
	border: 0px;
}

.schedule-list__input--error {
	border-color: $danger;
	width: 55px;
	margin-bottom: 26px;
}

.publish-menu__modal .cuboh-checkbox-group-item {
	display: block;
	margin-right: 0;
	margin-bottom: 8px;
}

.publish-menu__modal .cuboh-modal-body {
	padding-left: 50px;
}

.publish-menu__modal .cuboh-modal-content {
	margin-top: 75px;
}

.publish-menu__modal .cuboh-modal-title {
	font-weight: bold;
}

.publish-menu__modal .cuboh-checkbox-checked .cuboh-checkbox-inner,
.import_integration__modal .cuboh-radio-inner::after {
	background-color: $secondary-6;
	border-color: $secondary-6;
}

.publish-menu__modal
	.cuboh-checkbox-indeterminate
	.cuboh-checkbox-inner::after {
	background-color: $secondary-6;
}

.cuboh-anchor-wrapper {
	background-color: unset;
}

.cuboh-anchor-link-title:hover {
	color: $info;
}

.create-menu-drawer__row,
.edit-menu-drawer__row,
.add-item-drawer__row {
	margin-bottom: 24px;
}

.editable-field {
	margin: 0px;
}

.editable-field__input {
	min-height: 32px;
}

.cuboh-drawer-body {
	.editable-field__input {
		min-width: 250px;
	}
}

.editable-field__input__day-selector.cuboh-select,
.edit-menu__day-label.cuboh-col {
	width: 75px !important;
}
.edit-menu__date-label {
	width: 135px !important;
}
.editable-field__input__hours-selector.cuboh-picker {
	height: 32px;
	width: 190px;
}
.edit-menu__hours-label {
	width: 190px;
}

.edit-item-drawer__row {
	margin-bottom: 24px;
}

.edit-item-drawer__image {
	min-width: 400px;
}

.edit-item-drawer {
	&__selector {
		.cuboh-select-selector {
			width: 269px !important;
		}
	}
}

.edit-item-drawer__selector.error {
	border: 1px solid $danger;
	border-radius: 2px;
}

.create-menu__button,
.import_integration__button {
	margin: 10px 5px 25px;
}
.import_integration__button__hidden {
	display: none;
}

.classification-selector .cuboh-form-item-explain-error {
	display: none;
}

.classification-selector__error {
	font-size: 10px;
	color: $danger;
}

.antd-img-crop-control .cuboh-slider {
	width: 100%;
}

.edit-item-drawer__selector__price {
	height: 32px;
}

.edit-item-drawer__selector__price.error {
	border: 1px solid $danger;
	border-radius: 2px;
}

.storefront-prerequisite-step {
	.cuboh-steps-item-finish {
		.cuboh-steps-item-icon {
			border: none;
		}
	}

	&__warning {
		white-space: normal;
		height: auto;
		text-align: left;
	}

	&__warning.cuboh-btn-link {
		color: $secondary;
		transition: color 0.5s ease-out;

		span {
			text-decoration: underline;
		}
	}

	&__warning.cuboh-btn-link:hover {
		color: $secondary-5;
	}
}

.non-selectable-tree-node.cuboh-select-tree-treenode.cuboh-select-tree-treenode-switcher-open {
	:hover {
		background-color: #fff;
	}
}

.associated-merchant-list {
	height: 96px;
	overflow-y: scroll;
}

.publish-menu__collapse {
	max-height: 300px;
	overflow-y: scroll;
}

.master-menu-banner {
	color: #eb2f96;
	border: 1px solid #ffadd2 !important;
	background: #fcf1f6;
}

.duplicate-menu {
	&__modal {
		.cuboh-checkbox-group-item {
			display: block;
			margin-right: 0;
			margin-bottom: 8px;
		}

		.cuboh-modal-body {
			padding-left: 50px;
		}
	}
}

.duplicate-menu__modal .cuboh-modal-content {
	margin-top: 75px;
}

.duplicate-menu__modal .cuboh-modal-title {
	font-weight: bold;
}

.duplicate-menu__modal .cuboh-checkbox-checked .cuboh-checkbox-inner,
.import_integration__modal .cuboh-radio-inner::after {
	background-color: $secondary-6;
	border-color: $secondary-6;
}

.duplicate-menu__modal
	.cuboh-checkbox-indeterminate
	.cuboh-checkbox-inner::after {
	background-color: $secondary-6;
}

.is-default-checkbox {
	margin: 0 42% 0 42%;
}

.duplicate-modal {
	&__checkboxes {
		max-width: 250px;
		margin-top: 20px;
		margin-left: 10px;
	}
	&__group-layout {
		display: block;
	}
}

.duplicate-modal__checkboxes .duplicate-modal__checkbox-group {
	margin-bottom: 20px;
}

.duplicate-menu__modal .cuboh-checkbox-group-item {
	display: flex;
}

.cuboh-notification-warning {
	background: $warning-2;
	.notification-button-accept {
		background-color: $secondary;
		color: white;
		border: none;

		&:hover {
			background-color: $secondary-5;
			color: white !important;
		}
	}
	.notification-button-cancel {
		border: none;
	}
}

.cuboh-message {
	&-notice-success,
	&__auto-save-success,
	&__pos-refresh-success,
	&__import-success {
		.cuboh-message-notice-content {
			background-color: $success-3;
			color: $primary;

			.cuboh-message-success > .anticon {
				color: $success !important;
				border: 1px white;
			}
		}
	}
	&-notice-error,
	&__auto-save-error,
	&__pos-refresh-error,
	&__import-error,
	&__publish-error {
		.cuboh-message-notice-content {
			background-color: $warning-3;
			color: $primary;
			.cuboh-message-error > .anticon {
				color: $warning-6 !important;
			}
		}
	}

	&__import-warning {
		.cuboh-message-notice-content {
			background-color: $warning-3;
			color: $primary;
			.cuboh-message-error > .anticon {
				color: $warning-6 !important;
			}
		}
	}
}

.menu-actions {
	&__grouped-button {
		border-radius: 2px;
		.cuboh-btn-compact-item {
			background: white !important;
			border: 1px solid #d9d9d9 !important;

			&:first-child {
				border-top-left-radius: 2px;
				border-bottom-left-radius: 2px;
			}
			&:last-child {
				border-top-right-radius: 2px;
				border-bottom-right-radius: 2px;
			}
		}
	}
	&__import,
	&__create,
	&__publish,
	&__pos-refresh {
		&:disabled {
			background-color: transparent !important;
			border: none;
		}
	}

	&__notification-button-accept {
		background-color: $secondary;
		color: white;
		border: none;

		&:hover {
			background-color: $secondary-5;
			color: white !important;
		}
	}
}

.cuboh-table-cell span {
	white-space: nowrap;
}

.cuboh-dropdown-menu-item-active {
	.menu-actions {
		&__import,
		&__create,
		&__publish,
		&__pos-refresh {
			width: 100%;
			text-align: left;
			background-color: $microdose-2 !important;
			&:hover {
				width: 100%;
				text-align: left;
				background-color: $microdose-2 !important;
			}
		}
	}
}

.menu-search {
	&__header-search-bar.cuboh-input {
		width: 300px;
		height: 32px;
	}
	&__search-bar.cuboh-input {
		margin-right: 8px;
	}
	&__tooltip.cuboh-btn {
		border-top-right-radius: 2px !important;
		border-bottom-right-radius: 2px !important;
	}

	&__button.cuboh-btn {
		button {
			height: 32px;
			border-top-right-radius: 2px !important;
			border-bottom-right-radius: 2px !important;
		}
	}
}

.publishing-menus {
	position: relative;
	top: 1px;
}

.menu-names {
	&__list {
		.cuboh-list-item {
			padding-top: 4px;
			padding-bottom: 4px;
		}
	}
}

@keyframes color {
	to {
		background-color: $success;
	}
}

.menu-form {
	&__edited {
		.cuboh-float-btn-body {
			//background-color: $success !important;
			color: $primary;
			animation-name: color;
			animation-duration: 1s;
			animation-iteration-count: infinite;
			animation-direction: alternate-reverse;
			animation-timing-function: ease;

			svg {
				color: $primary;
			}
			.cuboh-float-btn-content {
				.cuboh-float-btn-description {
					color: $primary;
				}
			}
		}
	}
	&__float-button-save {
		width: 46px;
		.cuboh-float-btn-body {
			width: 38px !important;
			&:hover {
				background-color: $success;
				color: $primary;

				svg {
					color: $primary;
				}
				.cuboh-float-btn-content {
					.cuboh-float-btn-description {
						color: $primary;
					}
				}
			}
			.cuboh-spin {
				position: relative;
				top: -2px;
				color: white;
			}
		}
	}
	&__float-button-reset {
		width: 46px;
		.cuboh-float-btn-description {
			color: $primary;
		}
		.cuboh-float-btn-body {
			width: 38px !important;
			&:hover {
				background-color: $danger;
				color: $primary;
				svg {
					color: $primary;
				}
				.cuboh-float-btn-content {
					.cuboh-float-btn-description {
						color: $primary;
					}
				}
			}
		}
	}
	&__float-button-back-to-top {
		.cuboh-float-btn-body {
			&:hover {
				background-color: $microdose;

				svg {
					color: $primary;
				}
			}
		}
	}
}

.menu-management {
	&__segmented-menu-tabs {
		.cuboh-segmented-item-selected,
		.cuboh-segmented-item {
			color: $primary;

			&:hover {
				color: $primary !important;
			}
		}
	}
}

.edit-menu {
	&__section-title {
		color: $primary !important;
	}
	&__menu-details.cuboh-card,
	&__menu-hours.cuboh-card,
	&__menu-integrations.cuboh-card,
	&__menu-merchants.cuboh-card,
	&__menu-published.cuboh-card {
		border: 1px solid $primary !important;
		border-radius: 6px;
		box-shadow: none !important;
		padding: 8px 12px;
		.cuboh-card-body {
			padding: 0px;
		}
	}
	&__menu-merchants {
		margin-top: 16px;
	}
	&__menu-integrations,
	&__menu-merchants,
	&__menu-published {
		.cuboh-alert {
			margin-top: 12px;
		}
	}
	&__menu-merchants,
	&__menu-hours,
	&__menu-published {
		&-delete {
			svg {
				position: relative;
				top: -2px;
				right: 3px;
			}
			width: auto !important;
			.cuboh-btn {
				width: auto;
			}
		}
	}
	&__menu-hours,
	&__menu-merchants,
	&__menu-published {
		margin-top: 16px;
	}
	&__form-item {
		margin: 12px 0;
	}
	&__merchant-alert {
		margin-top: 12px;
	}
	&__more {
		padding: 0 6px;
	}
	&__export-button {
		margin-top: 16px;
	}
}
