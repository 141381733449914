// Default attribute is 'color'
@mixin color-modifiers($attribute: 'color') {
	// Loop through each of our colors, create a --{color} modifier for the attribute
	@each $name, $hex in $colors {
		&--#{'' + $name} {
			#{$attribute}: $hex;
		}
	}
}

$max: 50;
$offset: 1;
$unit: 'px';
@mixin generate-utils($className, $styleName) {
	$i: 0;
	@while $i <= $max {
		#{$className + $i} {
			#{$styleName}: #{$i + $unit};
		}
		$i: $i + $offset;
	}
}

$max: 50;
$offset: 1;
$unit: 'px';
@mixin generate-utils-important($className, $styleName) {
	$i: 0;
	@while $i <= $max {
		#{$className + $i} {
			#{$styleName}: #{$i + $unit} !important;
		}
		$i: $i + $offset;
	}
}

$max: 500;
$offset: 1;
$unit: 'px';
@mixin generate-dimensions($className, $styleName) {
	$i: 15;
	@while $i <= $max {
		#{$className + $i} {
			#{$styleName}: #{$i + $unit};
		}
		$i: $i + $offset;
	}
}

$max: 100;
$offset: 2;
$widthUnit: 'vw';
@mixin generate-width($className, $styleName) {
	$i: 0;
	@while $i <= $max {
		#{$className + $i} {
			#{$styleName}: #{$i + $widthUnit};
		}
		$i: $i + $offset;
	}
}

// Width
@include generate-width('.vw-', 'width');

// Margins
@include generate-utils('.mt-', 'margin-top');
@include generate-utils('.mr-', 'margin-right');
@include generate-utils('.mb-', 'margin-bottom');
@include generate-utils('.ml-', 'margin-left');
@include generate-utils('.m-', 'margin');

// Margins important
@include generate-utils-important('.mt-i-', 'margin-top');
@include generate-utils-important('.mr-i-', 'margin-right');
@include generate-utils-important('.mb-i-', 'margin-bottom');
@include generate-utils-important('.ml-i-', 'margin-left');
@include generate-utils-important('.m-i-', 'margin');

// Padding
@include generate-utils('.pt-', 'padding-top');
@include generate-utils('.pr-', 'padding-right');
@include generate-utils('.pb-', 'padding-bottom');
@include generate-utils('.pl-', 'padding-left');
@include generate-utils('.p-', 'padding');

// Padding Important
@include generate-utils-important('.pt-i-', 'padding-top');
@include generate-utils-important('.pr-i-', 'padding-right');
@include generate-utils-important('.pb-i-', 'padding-bottom');
@include generate-utils-important('.pl-i-', 'padding-left');
@include generate-utils-important('.p-i-', 'padding');

// Height / Width
@include generate-dimensions('.height-', 'height');
@include generate-dimensions('.width-', 'width');
.width {
	&-full {
		width: 100%;
	}
	&-half {
		width:50%;
	}
}

// Adapted from
// Media-Query Mixin - /https://dev.to/nidhishs/5-scss-mixins-to-check-out-2bkn

@mixin media($from: false, $until: false, $and: false, $media-type: all) {
	$min-width: 0;
	$max-width: 0;
	$query: '';

	//FROM: this breakpoint (inclusive)
	@if $from {
		@if type-of($from) == number {
			$min-width: $from;
		} @else {
			$min-width: map-get($breakpoints, $from);
		}
	}

	//UNTIL: this breakpoint (exclusive)
	@if $until {
		@if type-of($until) == number {
			$max-width: $until - 1px;
		} @else {
			$max-width: map-get($breakpoints, $until) - 1px;
		}
	}

	@if $min-width != 0 {
		$query: '#{$query} and (min-width: #{$min-width})';
	}
	@if $max-width != 0 {
		$query: '#{$query} and (max-width: #{$max-width})';
	}
	@if $and {
		$query: '#{$query} and (#{$and})';
	}

	@if ($media-type == 'all' and $query != '') {
		$media-type: '';
		$query: str-slice(unquote($query), 6);
	}

	@media #{$media-type + $query} {
		@content;
	}
}

// Generates mixins to hide when > a breakpoint
@mixin generate-hidden-from() {
	@each $name, $width in $breakpoints {
		.hidden-from--#{$name} {
			@include media($from: $width) {
				display: none;
			}
		}
	}
}

// Generates mixins to hide when < a breakpoint
@mixin generate-hidden-until() {
	@each $name, $width in $breakpoints {
		.hidden-until--#{$name} {
			@include media($until: $width) {
				display: none;
			}
		}
	}
}

@mixin disable-pointer-events($disable){
	@if $disable {
		pointer-events: 'none';
	}
}

@include generate-hidden-from();
@include generate-hidden-until();
