.suggestion-item--active {
	background-color: rgba(209, 173, 255, 0.1);
	cursor: pointer;
	display: block;
}

.suggestion-item {
	cursor: pointer;
	display: block;
}

.location-search-input__error {
	border-color: $danger !important;
}

.edit-storefront__form {
	.cuboh-form-item,
	.cuboh-form-item-with-help {
		margin-bottom: 32px;
	}
}

.edit-storefront__location-map {
	height: 300px;
}

.qr-code__modal .cuboh-modal-body {
	text-align: center;
}

.qr-code__modal .cuboh-modal-content {
	max-width: 600px;
}

.qr-code__modal .qr-code__modal__cancel-button {
	display: none;
}

.qr-code__icon {
	font-size: 22px;
}

.upload-list-inline .cuboh-upload-list-item {
	float: left;
	width: 175px;
	margin-right: 8px;
}

.upload-list-inline [class*='-upload-list-rtl'] .cuboh-upload-list-item {
	float: right;
}

.edit-storefront-sandbox-header {
	background-color: $info !important;
}

.storefront-name.cuboh-btn-link {
	color: $secondary;
	transition: color 0.5s ease-out;

	span {
		text-decoration: underline;
	}
}

.storefront {
	&__header {
		padding-top: 12px;
		z-index: 128;
		background: white;

		&-container {
			padding: 0;
		}
	}
}

.storefront-list {
	.cuboh-table-content,
	.cuboh-table {
		box-shadow: none !important;
	}
}

.storefront-name.cuboh-btn-link:hover {
	color: $secondary-5;
}

.storefront-register {
	height: 100vh;
}

.storefront-account {
	height: 100vh;

	&__title-row {
		text-align: center;

		@include media($until: 'md') {
			text-align: left;
		}
	}

	&__menu-row {
		@include media($until: 'md') {
			display: none;
		}
	}

	&__select-row {
		@include media($from: 'md') {
			display: none;
		}
	}

	&__menu {
		position: relative;
		display: flex;
		justify-content: center;

		.cuboh-menu-submenu {
			border: none;
		}
	}

	a {
		color: $primary;
	}

	.cuboh-card-meta {
		margin-bottom: 10px !important;
	}

	.cuboh-menu-light {
		.cuboh-menu-item-selected,
		.cuboh-menu-item-active {
			color: $secondary !important;

			&:after {
				border-bottom-color: $secondary !important;
			}
		}
	}
}

.order-history-list {
	height: 50vh;
	overflow: auto;
}

.payment-method__card {
	.cuboh-card-head {
		border-bottom: 1px solid #f0f0f0 !important;
	}
}

.company-storefront {
	&__input-field {
		min-width: 120px;
	}

	&__number-input-field {
		min-width: 64px;
	}

	&__phone-input-field {
		min-width: 210px;
		&--error {
			.cuboh-input {
				border-color: $danger;
			}
			.cuboh-input:hover {
				border-color: #fec8bd;
			}
			.cuboh-input:focus {
				border-color: $danger;
				box-shadow: 0px 0px 0px 2px #fee5e2;
			}
		}
	}
}

.order-total-button {
	pointer-events: none;
}

.modal-close-wrapper {
	border-radius: 50%;
	background-color: $danger;
	:active {
		background-color: $primary;
		border-radius: 50%;
	}
}

.storefront-locations {
	&__header {
		background: white;
		.cuboh-typography {
			margin-bottom: 0 !important;
		}
	}
	&__content.cuboh-layout {
		overflow-y: hidden;
		height: 90vh;
	}

	&__sider {
		background: white !important;
		border-right: 1px solid rgba(0, 0, 0, 0.06);
		overflow-y: scroll;
		overflow-x: hidden;
		position: fixed;
		right: 0;
		top: 0px;
		width: 420px;
		&__search {
			@include media($until: 'sm') {
				width: 250px;
			}
			width: 375px;
			height: 32px;
			margin: auto;
			margin-bottom: 45px;
			button {
				background-color: $primary;
				svg {
					margin-bottom: 3px;
				}
			}
			.cuboh-btn-primary:not(:disabled) {
				background-color: $primary;
			}
			.cuboh-btn-primary:not(:disabled):hover {
				background-color: $primary-3;
			}
			.cuboh-btn-primary:active {
				background-color: $primary-4;
			}
		}
	}
	&__error {
		height: 85vh;
	}
	&__list {
		@include media($until: 'sm') {
			width: 250px;
		}
		width: 380px;
		margin: auto;
	}
}
.map__spinner {
	margin: auto;
	display: block;
	padding-top: 25%;
}

.kiosk {
	&__home-button {
		height: 91vh;
	}
	&__home-title {
		color: black;
	}
}

.storefront-layout {
	min-height: 94vh;
	padding-bottom: 132px;
	padding-right: 0px;

	&__footer--fixed {
		bottom: 0;
		position: fixed;
		width: 100%;
		z-index: 6;
	}
}
