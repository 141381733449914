.cuboh-table-cell {
	a {
		color: $primary;
	}
}

.cuboh-table-column-sort {
	background: #f5f5f5 !important;
}

.cuboh-table-expanded-row {
	.cuboh-pagination {
		padding: 10px 0;
		margin-bottom: 0px !important;
		margin-top: 30px !important;
	}
}

.row {
	&--posException {
		background-color: $warning-1 !important;
	}
}

.order-report__service-type {
	min-width: 75px;
}
