.select {
  &--notify {
    width: 288px;
  }
}

.avatar {
  &--notify {
    margin-top: '20px';
    margin-bottom: '20px';
    margin-right: '0';
  }
}