.live-orders {
    &__card {
        overflow: scroll;
        height: 250px;
        width: 400px;
    }
}

.widget-group-cb {
	display: block;
}

.widget-collapse{
    .cuboh-collapse-expand-icon {
        margin-top:10px;
    }
}

.widget-selector{
    min-width: 150px;
}
