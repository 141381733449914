.mapping {
	&__tab-select {
		margin-left: 10px;
	}

	&__header-button {
		margin-right: 10px;
		margin-left: 0px;
		width: 160px;
	}
}

.action-bar {
	&--filters {
		padding: 10px 24px 10px;
   -webkit-box-shadow: 0px 3px 3px -3px rgb(0 0 0 / 40%);
   -moz-box-shadow: 0px 3px 3px -3px rgb(0 0 0 / 40%);
   box-shadow: 0px 3px 3px -3px rgb(0 0 0 / 40%);
	}
	&--controls {
		padding: 5px 0px;
	}
	&__select {
		margin: 10px;
		&--large {
			width: 10rem;
		}
		&--small {
			width: 5rem;
		}
	}
	&__tag {
		padding: 5px;
	}
	&__column-button {
		width: 160px;
	}
}

.column-filter {
	&__name {
		margin-left: 10px;
	}
}

.text-filter,
.numeric-filter {
	padding: 10px;
	&__input {
		width: 190px;
		display: block;
		margin-bottom: 8px;
	}
	&__button {
		width: 90px;
	}
}

.location-select {
	width: 10rem;
}

.approved-radio {
	&__show-new {
		border-radius: 0 4px 4px 0;
	}
}

.cuboh-radio-button-wrapper-checked .approved-radio__show-new__badge {
	color: #fff;
}

.mapping-content-button-row{
	min-width:400px;
}


